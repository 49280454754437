<template>
  <div class="Header">
    <div :class="{'area':true,'showBg':showBg}">
      <div class="item item1" @click="toPage('/home')">
        <img class="logo" src="@/assets/logo.png" alt />
        <span class="title">中昊供应链</span>
      </div>
      <div class="item item2"></div>
      <ul class="item item3">
        <li
          v-for="(item,index) in menuList"
          :key="index"
          :class="{active:routeName == item.path}"
          @click="toPage(item.path)"
        >{{ item.name }}</li>
        <!-- app下载单独处理事件 -->
        <li @mouseenter="(e)=>mouseEvt(e,'enter')" @mouseleave="(e)=>mouseEvt(e,'leave')">APP下载</li>
      </ul>
      <div class="item item4" @click="drawer = true">
        <img class="img" src="@/assets/list.png" alt />
      </div>
    </div>
    <div class="backBtn" v-if="routeName == '/newsDetail'" @click="toPage('/news')">
      <img :src="backBtnUrl" alt />
    </div>
    <div v-if="showDownload" class="appDown" ref="appDown">
      <img src="@/assets/index/bottom/app.png" alt />
    </div>
    <el-drawer
      title="中昊供应链"
      size="50%"
      :visible.sync="drawer"
      direction="rtl"
      :append-to-body="true"
    >
      <ul class="list">
        <li v-for="(item,index) in menuList" :key="index" @click="toPage(item.path)">{{ item.name }}</li>
      </ul>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showDownload: false,
      drawer: false,
      showBg: false,
      backBtnUrl: require("@/assets/header/back-light.png"),
      menuList: [
        { name: "首页", path: "/" },
        { name: "行业动态", path: "/news" },
        { name: "货源信息", path: "/source" },
        { name: "运力信息", path: "/transport" },
        { name: "关于我们", path: "/aboutus" },
        { name: "业务统计", path: "/statistics" }
      ]
    };
  },
  mounted() {
    this.initial();
  },
  computed: {
    routeName() {
      return this.$route.path;
    }
  },
  methods: {
    async initial() {
      let _this = this;
      window.addEventListener("scroll", function() {
        // 滚动事件触发后执行的代码
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        _this.showBg = scrollTop > 5;
        // console.log('_this.showBg',_this.showBg);
        
        _this.backBtnUrl = !_this.showBg
          ? require("@/assets/header/back-light.png")
          : require("@/assets/header/back-dark.png");
        let app = document.getElementById("app");
        app.style.setProperty("--fontColor", scrollTop > 5 ? "#333" : "#fff");
      });
    },
    mouseEvt(e, type) {
      if (type == "enter") {
        this.showDownload = true;
        let traget = e.target;
        let rect = traget.getBoundingClientRect();
        this.$nextTick(() => {
          this.$refs.appDown.style.left = rect.left - 40 + "px";
          this.$refs.appDown.style.top = rect.bottom + 20 + "px";
        });
      } else {
        this.showDownload = false;
      }
    },
    toPage(path) {
      this.$router.push(path);
      this.drawer = false;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.Header {
  position: relative;
  width: 100%;
  height: $headerHeight;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 999;
  .backBtn {
    position: absolute;
    left: 5px;
    top: 20px;
    img {
      width: 30px;
      cursor: pointer;
    }
  }
  .appDown {
    position: fixed;
    left: 0;
    top: 0;
    img {
      width: 150px;
    }
  }
  .area {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    transition: all 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
  }
  .showBg {
    width: 100%;
    height: 90%;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    color: $fontColor;
    .title {
      transition: all 0.3s;
      margin-left: 15px;
    }
  }
  .item {
    display: flex;
    align-items: center;
  }
  .item1 {
    font-size: 24px;
    cursor: pointer;
    .logo{
      width: 60px;
    }
    .title{
      margin-left: 15px;
    }
  }

  .item3 {
    list-style: none;
    font-size: 14px;
    font-weight: bold;

    li {
      margin: 0 20px;
      cursor: pointer;
      position: relative;
      transition: all 0.3s;

      &:hover {
        color: $fontHoverColor;
      }
      &:hover::after {
        width: 100%;
        transform: translateX(-50%);
        opacity: 1;
      }
      &::after {
        width: 0%;
        content: "";
        height: 4px;
        background-color: var(--fontColor);
        position: absolute;
        bottom: -10px;
        left: 50%;
        right: auto;
        opacity: 0;
        transition: all 0.3s;
      }
    }
    .active {
      // position: relative;
      &::after {
        width: 100%;
        content: "";
        height: 4px;
        background-color: var(--fontColor);
        position: absolute;
        bottom: -10px;
        left: 0;
        right: auto;
        opacity: 1;
        transition: all 0.3s;
      }
      &:hover {
        color: $fontHoverColor;
      }
      &:hover::after {
        width: 100%;
        transform: none;
        opacity: 1;
      }
    }
  }
  .item4 {
    display: none;
    .img {
      width: 25px;
    }
  }

  @media screen and (max-width: 767px) {
    .item3 {
      display: none;
    }
    .item4 {
      display: block;
    }
  }
}
</style>
